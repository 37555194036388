var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.fieldsLoading
        ? _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            _vm._l(12, function (n) {
              return _c(
                "v-col",
                { key: n, attrs: { cols: "4" } },
                [
                  _c(
                    "v-sheet",
                    { staticClass: "mt-2 px-3" },
                    [
                      _c("v-skeleton-loader", {
                        staticClass: "mx-auto",
                        attrs: { type: "image", height: "75" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          )
        : _c(
            "div",
            { staticClass: "px-0 mt-3" },
            [
              _c(
                "v-card",
                { attrs: { flat: "", tile: "" } },
                [
                  _vm._l(_vm.tilesList, function (item, index) {
                    return [
                      _c(
                        "v-row",
                        {
                          key: item.id + "_" + index + "_items",
                          staticClass: "mx-0 px-2",
                          class:
                            item.list.length > 3
                              ? "custom_border_color_style"
                              : "",
                        },
                        [
                          _vm._l(item.list, function (navItem, index) {
                            return [
                              _c(
                                "v-col",
                                {
                                  key: navItem.to + "_module_" + index,
                                  staticClass: "pa-0",
                                  class:
                                    _vm.setBorderRight(index + 1, item.list) +
                                    " " +
                                    _vm.setBorderBottom(index + 1, item.list),
                                  attrs: { cols: "4" },
                                },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "pa-0",
                                      attrs: {
                                        tile: "",
                                        flat: "",
                                        to: navItem.to,
                                      },
                                      on: {
                                        click: function ($event) {
                                          navItem.isCustomModules
                                            ? _vm.openInNewTab(navItem)
                                            : navItem.name ===
                                              _vm.$t("message.mixed.scanQRCode")
                                            ? _vm.scanning()
                                            : ""
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "text-center pt-1" },
                                        [
                                          _c(
                                            "v-avatar",
                                            [
                                              navItem.icon !== "mdi-qrcode-scan"
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "grey darken-3",
                                                        size: "33",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          navItem.icon
                                                            ? navItem.icon
                                                            : "mdi-cube"
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "svg",
                                                    {
                                                      staticStyle: {
                                                        width: "30px",
                                                      },
                                                      attrs: {
                                                        "aria-hidden": "true",
                                                        focusable: "false",
                                                        "data-prefix": "fas",
                                                        "data-icon": "scanner",
                                                        role: "img",
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        viewBox: "0 0 640 512",
                                                        "data-fa-i2svg": "",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          fill: "#424242",
                                                          d: "M368 64H96c-53 0-96 43-96 96 0 52.4 42.1 94.9 94.3 95.8L6.4 408C-6.9 431 1 460.3 24 473.6l55.4 32c23 13.3 52.3 5.4 65.6-17.6l133.9-232H368c8.8 0 16-7.2 16-16V80c0-8.8-7.2-16-16-16zM256 448c17.7 0 32-14.3 32-32V304.3L205 448h51zm376 0H456c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h176c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0-96H456c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h176c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zm0-288H456c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h176c4.4 0 8-3.6 8-8V72c0-4.4-3.6-8-8-8zm0-64H456c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h176c4.4 0 8-3.6 8-8V8c0-4.4-3.6-8-8-8zm0 288H456c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h176c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zm0-128H456c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h176c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-card-text",
                                        {
                                          staticClass:
                                            "text-center pa-2 font-weight-medium",
                                        },
                                        [_vm._v(_vm._s(_vm.$t(navItem.name)))]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          }),
                        ],
                        2
                      ),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
      _c(
        "v-dialog",
        {
          staticClass: "mt-0",
          attrs: { persistent: "", "max-width": "500px" },
          model: {
            value: _vm.scanDialog,
            callback: function ($$v) {
              _vm.scanDialog = $$v
            },
            expression: "scanDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(" " + _vm._s(_vm.$t("message.common.scan")) + " "),
              ]),
              _c("v-divider"),
              _c("v-card-text", { staticClass: "pa-1" }, [
                _vm._v(" " + _vm._s(_vm.scanError) + " "),
                _c("div", [
                  _c("video", {
                    ref: "video",
                    staticStyle: {
                      border: "1px solid gray",
                      width: "100%",
                      height: "auto",
                    },
                    attrs: { id: "video" },
                  }),
                ]),
                _vm.videoInputDevices.length > 1
                  ? _c(
                      "div",
                      { attrs: { id: "sourceSelectPanel" } },
                      [
                        _c("v-select", {
                          attrs: {
                            id: "sourceSelect",
                            outlined: "",
                            items: _vm.videoInputDevices,
                            "hide-details": "",
                            "item-text": "label",
                            "item-value": "deviceId",
                            label: _vm.$t("message.common.changeVideoSource"),
                          },
                          model: {
                            value: _vm.selectedDeviceId,
                            callback: function ($$v) {
                              _vm.selectedDeviceId = $$v
                            },
                            expression: "selectedDeviceId",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", id: "scanClose" },
                      on: { click: _vm.stopScanningSession },
                    },
                    [_vm._v(_vm._s(_vm.$t("message.common.close")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          staticClass: "mt-0",
          attrs: { persistent: "", "max-width": "500px" },
          model: {
            value: _vm.showProjectSelectDialog,
            callback: function ($$v) {
              _vm.showProjectSelectDialog = $$v
            },
            expression: "showProjectSelectDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(
                  " " + _vm._s(_vm.$t("message.projects.selectProject")) + " "
                ),
              ]),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticClass: "pa-1" },
                [
                  _vm.isProjectsByUsedProductLoading
                    ? _c(
                        "v-sheet",
                        { staticClass: "d-flex justify-center pa-5" },
                        [
                          _c("v-progress-circular", {
                            attrs: { indeterminate: "" },
                          }),
                        ],
                        1
                      )
                    : _c("v-autocomplete", {
                        attrs: {
                          items: _vm.selectableProjectForPickup,
                          "item-text": function (item) {
                            return (
                              (item.po_ranked ? item.po_ranked : "") +
                              " " +
                              (item.project_name
                                ? " - " + item.project_name
                                : "") +
                              " " +
                              (item.ao_ranked ? " - " + item.ao_ranked : "") +
                              " " +
                              (item.work_order_name
                                ? " - " + item.work_order_name
                                : "")
                            )
                          },
                          "item-value": "timelogapp_id",
                          label: _vm.$t("message.projects.selectProject"),
                          "menu-props": "auto, overflowY",
                          outlined: "",
                          dense: "",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.gotoPickup(_vm.seletedProject)
                          },
                        },
                        model: {
                          value: _vm.seletedProject,
                          callback: function ($$v) {
                            _vm.seletedProject = $$v
                          },
                          expression: "seletedProject",
                        },
                      }),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.handleProjectSelection },
                    },
                    [_vm._v(_vm._s(_vm.$t("message.common.close")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }